// pages/_app.js
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import React from 'react'
import config from 'src/config'

if (typeof window !== 'undefined' && config.isProd) {
  posthog.init(config.posthog.key, {
    api_host: '/ingest',
    ui_host: 'https://us.posthog.com',
    person_profiles: 'always',
    loaded: (posthog) => {
      if (process.env.NODE_ENV === 'development') posthog.debug()
    }
  })
}

type IProps = {
  children: React.ReactNode
}

const Provider: React.FC<IProps> = ({ children }) => {
  if (!config.isProd) return <>{children}</>
  return <PostHogProvider client={posthog}>{children}</PostHogProvider>
}

export default React.memo(Provider)
